
import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IStation } from '@/interfaces';
import { readStations } from '@/store/stations/getters';
import { readEquipments } from '@/store/equipments/getters';
import { dispatchGetStationsForExporting } from '@/store/stations/actions';
import { dispatchGetEquipments } from '@/store/equipments/actions';
import axios from 'axios';
import { apiUrl } from '@/env';
import { getLocalToken } from '@/utils';
import { commitAddNotification } from '@/store/main/mutations';
import { store } from '@/store';
import { readHasAccess } from '@/store/main/getters';

const routeGuardAdmin = async (to, from, next) => {
  if (!readHasAccess(store)('EXPORT')) {
    next('/main');
  } else {
    next();
  }
};

@Component
export default class Export extends Vue {
  public category = '';
  public station = '';
  public equipment = '';
  public modalinidate = false;
  public modalenddate = false;
  public modalinitime = false;
  public modalendtime = false;
  public fileFormat = 'xlsx';
  public qcFlags = false;
  public beginningOfTime = this.$moment('2015-01-01')
    .startOf('day')
    .toISOString()
    .substr(0, 10);
  public now = this.$moment()
    .toISOString()
    .substr(0, 10);
  public initialDate = this.$moment()
    .subtract(7, 'days')
    .startOf('day')
    .toISOString()
    .substr(0, 10);
  public finalDate = this.$moment()
    .startOf('day')
    .toISOString()
    .substr(0, 10);
  public initialTime = '00:00';
  public finalTime = '23:59';
  public interval = 'data';
  public exporting = false;

  public categories = [
    { value: 'wind', text: this.$root.$i18n.t('export.wind') },
    { value: 'wave', text: this.$root.$i18n.t('export.wave') },
    { value: 'weather', text: this.$root.$i18n.t('export.weather') },
    { value: 'tide', text: this.$root.$i18n.t('export.tide') },
    { value: 'current', text: this.$root.$i18n.t('export.current') },
  ];

  public beforeRouteEnter(to, from, next) {
    routeGuardAdmin(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardAdmin(to, from, next);
  }

  public filteredStations(station, category) {
    return this.stations
      .find((el) => el.location === station)!
      .equipments.filter((el) => el.categories.map((c) => c.name.toLowerCase()).includes(category))
      .map((el) => ({
        value: el.id,
        text: `${el.type} (${el.name})`,
      }));
  }

  get stations() {
    return readStations(this.$store).sort((a, b) =>
      a.location + a.id > b.location + b.id ? 1 : -1,
    );
  }

  get equipments() {
    return readEquipments(this.$store);
  }

  public async exportAllTheFucks() {
    this.exporting = true;
    await axios
      .get(`${apiUrl}/api/v1/export/category/${this.category}/equipment/${this.equipment}`, {
        headers: {
          Authorization: `Bearer ${getLocalToken() === null ? '' : getLocalToken()}`,
        },
        params: {
          initial_date: `${this.initialDate}T${this.initialTime}:00-03:00`,
          final_date: `${this.finalDate}T${this.finalTime}:00-03:00`,
          format: this.fileFormat,
          interval: this.interval,
          qc_flags: this.qcFlags && this.interval === 'data',
          qc_only: !(this.qcFlags && this.interval === 'data'),
        },
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        const contentDisposition = response.headers['content-disposition'];
        const regExpFilename = /filename="(?<filename>.*)"/;
        const filename =
          regExpFilename.exec(contentDisposition)?.groups?.filename || 'Desconhecido.xlsx';
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        if (error.response) {
          const enc = new TextDecoder('utf-8');
          const res = JSON.parse(enc.decode(new Uint8Array(error.response.data)));
          commitAddNotification(this.$store, {
            content: res.detail,
            color: 'error',
          });
        } else {
          commitAddNotification(this.$store, {
            content: 'Erro desconhecido ao exportar. Tente novamente.',
            color: 'error',
          });
        }
      });
    this.exporting = false;
  }

  public async mounted() {
    await dispatchGetStationsForExporting(this.$store);
    await dispatchGetEquipments(this.$store);
  }
}
